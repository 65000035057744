<div id="menu-about" class="rect-menu menu-about">
    <div class="stars"></div>
    <div id="about-carousel" class="menu-about-carousel">
        <div class="menu-about-carousel-page">
            <img src="./assets/imgs/rumor_white_logo.png" alt="rumor_white_logo" style="    width: 150px;
            margin:0 auto;    margin-top: 16px;">
            <div class="about-desc text-center py-3">
                <p>WE ARE A RECORD LABEL OF ICONIC, CULTURE - SHAPING MUSIC & ENTERTAINMENT. BUILT ON IRREVERENT IDEAS, CREATIVE RISKS, AND LIFE-CHANGING HITS. CREATED AS A HOME FOR ARTISTS — ESTABLISHED AND EMERGING, MAINSTREAM AND MAVERICK, LEGENDS AND
                    LEGENDS-TO-BE. RUN BY A FEARLESS TEAM OF MUSIC OBSESSIVES. WE ARE RUMOR RECORDS.</p>
            </div>
        </div>
        <div class="menu-about-carousel-page">
            <div class="about-desc">
                <h2 class="about-title">Team</h2>
            </div>
            <div class="about-desc">
                <h3 class="about-list">Graham Kramer- Founder</h3>
                <!-- <h3 class="about-list">Steven Scope-Founder</h3> -->
                <!-- <h3 class="about-list">Eric Medrano - Founder</h3> -->
                <!-- <h3 class="about-list">Dillion Shamoun - Founder</h3> -->
            </div>
        </div>
        <!-- <div class="menu-about-carousel-page">
            <div class="about-desc">
                <h3 class="about-desc-text">Alex Shortt</h3>
                <hr />
                <h3 class="about-desc-text">18 years old</h3>
                <hr />
                <h3 class="about-desc-text">UC Santa Barbara '22</h3>
                <hr />
                <h3 class="about-desc-text">Bay Area / Santa Barbara</h3>
            </div>
        </div> -->
        <!-- <div class="menu-about-carousel-page">
            <div class="about-working"></div>
            <div class="about-desc">
                <h3 class="about-desc-text">I work hard.</h3>
            </div>
        </div> -->
        <!-- <div class="menu-about-carousel-page">
            <div class="about-desc">
                <h2 class="about-title">I know all this:</h2>
            </div>
            <div class="about-desc">
                <h3 class="about-list">HTML/CSS</h3>
                <h3 class="about-list">JS/jQuery</h3>
                <h3 class="about-list">Sass/Scss/Less</h3>
                <h3 class="about-list">React</h3>
                <h3 class="about-list">Stripe</h3>
                <h3 class="about-list">Shopify</h3>
                <h3 class="about-list">Google Analytics</h3>
                <h3 class="about-list">Git</h3>
                <h3 class="about-list">Node.js</h3>
                <h3 class="about-list">Java</h3>
            </div>
        </div> -->

        <!-- <div class="menu-about-carousel-page">
            <div class="about-mix"></div>
            <div class="about-desc">
                <h2 class="about-title">I mix music.</h2>
            </div>
        </div> -->
        <!-- <div class="menu-about-carousel-page">
            <div class="about-desc">
                <h2 class="about-title">In Short(t).</h2>
                <hr />
            </div>
            <div class="about-desc">
                <h3 class="about-list">I program.</h3>
                <h3 class="about-list">I design.</h3>
                <h3 class="about-list">I mix music.</h3>
            </div>
        </div> -->
    </div>
    <div class="menu-about-arrow">
        <i id="about-page-up" class="menu-about-arrow-button fa fa-arrow-circle-up" aria-hidden="true"></i>
        <i id="about-page-down" class="menu-about-arrow-button fa fa-arrow-circle-down" aria-hidden="true"></i>
    </div>
</div>