import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FullComponent} from './layout/full/full.component'
import {BlankComponent} from './layout/blank/blank.component'
import {HomeComponent} from './conponentPacakage/home/home.component'
import {NavbarComponent} from'./conponentPacakage/navbar/navbar.component'
import {MessageComponent} from './conponentPacakage/message/message.component'
import {ProjectComponent} from './conponentPacakage/project/project.component'
import {SocialComponent} from './conponentPacakage/social/social.component'
import {AboutComponent} from './conponentPacakage/about/about.component'
import {ArtistComponent} from './conponentPacakage/artist/artist.component'
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', component: FullComponent },
  {
    path: '', component: FullComponent,
    children: [
      { path: 'nav', component: ProjectComponent },
      { path: 'artist', component: ArtistComponent },
    ]
  },
  {
    path: '', component: BlankComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'message', component: MessageComponent },
      { path: 'social', component: SocialComponent },
      // { path: 'artist', component: ArtistComponent },
    ]
  },
  { path: '**', redirectTo: 'home' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
