import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'; ``
import * as jQuery from 'jquery'; import { Location } from '@angular/common';
import { CommonMethods } from 'src/app/UtillPackage/utilPackage/common-method';
import { Router, NavigationEnd } from '@angular/router';
``
@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {
    _book:any;
    myBoolean=false
    constructor(public location: Location,public router:Router) {
     
    }

    ngOnInit(): void {

        jQuery.fn.book = function(opts) {
            opts = jQuery.extend({}, jQuery.fn.book.defs, opts);
            this.initialize = function() {
                this.hideAllPages();
                return this;
            }
            jQuery.fn.book.defs = {};
            var instance = this;
            var element = jQuery(this);
        
            // 0 is front cover
            var curPage = 0;
            // not including back cover
            const numPages = $(".book-page > li").length;
          
            this.changePage = function(newPage) {
                this.closeGallery();
                curPage = newPage;
               
                this.updatePage();
            }
        
            this.updatePage = function() {
                if (curPage > numPages + 1)
                    curPage = numPages + 1;
                this.hideAllPages();
                this.showPage(curPage);
                this.closeGallery();
        
                var randomInterval = .125;
        
                // front cover: open or close
                this.closePage(0);
        
         
                if (curPage != 0) {
                    this.openPage(0, -145);
                }
        
                // pages that flip towards front cover
                var numFront = curPage - 1;
            
                var frontAngles = [-145, -110];
                var frontTimes = [1.2, 1.8];
                for (var i = 1; i <= numFront; i++) {
                    var angle = ((frontAngles[1] - frontAngles[0]) / numFront) * i;
                    angle += frontAngles[0];
                    var time = ((frontTimes[1] - frontTimes[0]) / numFront) * i;
                    time += frontTimes[0]
                    time += (Math.random() - 0.5) * randomInterval;
                    this.openPage(i, angle, time);
                }
        
                // change distribution to max - diff, not min + diff
                // pages that flip towards back cover
                var numBack = numPages - curPage + 1;
                var backAngles = [-16, -35];
                var backTimes = [1.8, 2];
                for (var i = curPage; i <= numPages; i++) {
                    var angle = ((backAngles[1] - backAngles[0]) / numBack) * (numPages - i);
                    angle += backAngles[0];
                    var time = ((backTimes[1] - backTimes[0]) / numBack) * (numPages - i);
                    time += backTimes[0]
                    time += (Math.random() - 0.5) * randomInterval;
                    this.openPage(i, angle, time);
                }
            }
        
            this.openPage = function(numPage, angle, time) {
                this.closePage(numPage);
                if (numPage == 0) {
                    var selector = ".book-cover-front";
        
                    $(selector).css("-webkit-transform", "rotateY(" + angle + "deg)  translateZ(0)");
                    $(selector).css("-moz-transform", "rotateY(" + angle + "deg)  translateZ(0)");
                    $(selector).css("transform", "rotateY(" + angle + "deg)  translateZ(0)");
                    if (time) {
                        $(selector).css("-webkit-transition-duration", time + "s");
                        $(selector).css("-moz-transition-duration", time + "s");
                        $(selector).css("transition-duration", time + "s");
                    }
                    $(selector).css("z-index", 0);
                } else {
                    var selector = ".book-page li:nth-child(" + numPage + ")";
        
                    $(selector).css("-webkit-transform", "rotateY(" + angle + "deg)");
                    $(selector).css("-moz-transform", "rotateY(" + angle + "deg)");
                    $(selector).css("transform", "rotateY(" + angle + "deg)");
                    $(selector).css("-webkit-transition-duration", time + "s");
                    $(selector).css("-moz-transition-duration", time + "s");
                    $(selector).css("transition-duration", time + "s");
                }
            }
        
            this.hideAllPages = function() {
                $(".page:not(.page-toc)").each(function(ind, obj) {
                    $(obj).find(".page-content").addClass("hidden");
                    $(obj).find(".page-back").addClass("hidden");
                });
                $("#backcover-girl").addClass("hidden");
                $("#backcover-saturn").addClass("backcover-saturn-hidden");
            }
        
            this.showPage = function(numPage) {
                setTimeout(function() {
                    $(".book-page li:nth-child(" + numPage + ")").find(".page-content").removeClass("hidden");
                    $(".book-page li:nth-child(" + numPage + ")").find(".page-back").removeClass("hidden");
                }, 500);
                if (numPage == numPages + 1) {
                    setTimeout(function() {
                        $("#backcover-saturn").removeClass("backcover-saturn-hidden");
                        setTimeout(function() {
                            $("#backcover-girl").removeClass("hidden");
                        }, 250);
                    }, 1000);
                }
            }
        
            this.closePage = function(numPage) {
                var selector = numPage == 0 ?
                    ".book-cover-front" :
                    ".book-page li:nth-child(" + numPage + ")";
                $(selector).removeAttr("style");
            }
        
            this.toggleGallery = function(override) {
                const parent = $(".book-page li:nth-child(" + curPage + ")");
                if (parent.data("gallery") == "moving")
                    return;
                if (parent.data("gallery") != "moved") {
                    this.closeGallery();
                } else {
                    this.openGallery();
                }
            }
        
            this.openGallery = function() {
                const parent = $(".book-page li:nth-child(" + curPage + ")");
                parent.data("gallery", "moving");
                parent.find(".action-gallery").html("close");
                parent.find("#gallery").css("left", "0");
        
                // move down
                parent.addClass("page-gallery-open");
                setTimeout(function() {
                    // fade text out
                    //parent.addClass("page-gallery-opening");
                    parent.find(".page-content-text").css("color", "transparent");
                    setTimeout(function() {
                        // fade image in
                        parent.addClass("page-gallery-opened");
                        parent.data("gallery", 0);
                    }, 750);
                }, 750);
            }
        
            this.closeGallery = function() {
                const parent = $(".book-page li:nth-child(" + curPage + ")");
                parent.data("gallery", "moving");
                parent.find(".action-gallery").html("gallery");
        
                //fade image out
                parent.removeClass("page-gallery-opened");
                setTimeout(function() {
                    //fade text in
                    parent.find(".page-content-text").css("color", "white");
                    setTimeout(function() {
                        // close gallery
                        parent.removeClass("page-gallery-open");
                        parent.data("gallery", "moved")
                    }, 750);
                }, 750)
            }
        
            this.changeGallery = function(diff) {
                const parent = $(".book-page li:nth-child(" + curPage + ")");
                var items = parent.find("#gallery").children().length;
                var num = parent.data("gallery");
                if (num == "dicks")
                    return;
                num += diff;
                if (num < 0)
                    num = items - 1;
                if (num >= items)
                    num = 0;
                parent.data("gallery", num);
                parent.find("#gallery").css("left", "calc(" + num + " * (-100% - 4px))");
            }
        
            return this.initialize();
        }
     
        this.initProjects();
      
    }

    initProjects() {
      
       let _book = $(".book").book({});
        this._book = $(".book").book({});
   
     _book.updatePage();
        _book.changePage(0);
        setTimeout(() => {
           _book.changePage(1); 
        }, 1250);
     
        $(".page").each(function(ind, obj) {
            $(obj).children().each(function(ind, obj) {
                $(obj).click(function() {
                    if ($(this).data("page") == "0") {
                       _book.changePage(0);
                    //    setTimeout(() => {
                    //     this.location.back()      
                    // }, 750);
                    } else if ($(this).data("page")) {
                        _book.changePage(parseInt($(this).data("page")));
                    }
                })
            });
        });
     
        $(".action-visit").each(function () {
            if ($(this).data("link")) {
                $(this).click(function () {
                    window.open($(this).data("link"));
                });
            }
        });
      
        $(".action-prev").click(function () {
           _book.changeGallery(-1);
        });
      
        $(".action-next").click(function () {
           _book.changeGallery(1);
        });
      
        $(".action-gallery").click(function () {
           _book.toggleGallery();
        });


       

      }


      gotoback(){

    

      
        setTimeout(() => {
            this.location.back()      
        }, 1250);
         
      }



      gotoNavigation(path:any){
             if(path == 'artist'){
                setTimeout(() => {
                    this.router.navigate(['artist'])
                }, 1550);
             }else if(path == 'about'){
                setTimeout(() => {
                    this.router.navigate(['about'])
                }, 1550);

             }else if(path == 'message'){
                setTimeout(() => {
                    this.router.navigate(['message'])
                }, 1550);
            }else{
                setTimeout(() => {
                    this.router.navigate(['social'])
                }, 1550);
            }
      }



}
