import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'; ``
import * as jQuery from 'jquery';
import { CommonMethods } from './UtillPackage/utilPackage/common-method';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  title = 'bookFlip';
  _book;


  constructor(public router:Router){
  
  }

ngOnInit():void{

}

}
