import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonMethods } from 'src/app/UtillPackage/utilPackage/common-method';
import { Location } from '@angular/common';
// import * as $ from 'jquery';
import * as $ from 'jquery';``
import * as jQuery from 'jquery';
@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.css']
})
export class BlankComponent implements OnInit {
  Tag="BlankComponent"
  showpreviousButton=false;
  addclassSicialActive=false
  addclassArtistActive=false
  isactiveartist=false

  constructor(public router:Router,public location:Location) { 

    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {

         CommonMethods.showconsole(this.Tag,"Current Url:- "+this.router.url)
          if(this.router.url == '/nav' || this.router.url == '/social' || this.router.url == '/home'){
            this.showpreviousButton=false
          }else{
            this.showpreviousButton=true
          }

          if(this.router.url == '/social'   ){
            this.addclassSicialActive=true
          }else{
            this.addclassSicialActive=false
          }
          if(this.router.url == '/artist' ){
            this.addclassArtistActive=true
            this.isactiveartist=true;
             CommonMethods.showconsole(this.Tag,"Working Is ")
          }else{
            this.isactiveartist=false;
            this.addclassArtistActive=false
          }
          if(this.router.url == '/home'){
           document.getElementById('parallax-wrapper').style.background="#000000";
          }else{
            document.getElementById('parallax-wrapper').style.background="#ffffff";
          }

      }

    })
    // this.initParallax()
  }




  


  ngOnInit(): void {
 
      

  }


  back(){
      this.location.back();
  }
 

}
