<div class="container-fluid  py-3">

 <div class="desktopView">
      <div class="imgdestopimageDiv">
          <img src="./assets/imgs/desktopBorder.png" alt="desktopBorder">
          <div class="display">
               <div class="startingimage" *ngIf="firstScreenBoolean == true">
                     <div class="imageDiv">
                         <img src="./assets/imgs/rumor2.png" alt="rumor2">
                         <div class="pressimagetext">
                              <p><span><i class="fa fa-caret-right" aria-hidden="true"></i></span> Press Start</p>
                         </div>
                     </div>
               </div>
               <div class="commingsoonImage" *ngIf="commingsoonBoolean == true">
                     <div class="imageDiv">
                         <img src="./assets/imgs/comming.png" alt="comming">
                         <div class="pressimagetext">
                              <p>Comming Soon</p>
                         </div>
                     </div>
               </div>
               <div class="screenSecond" *ngIf="firstScreenBoolean == false && commingsoonBoolean == false">
                     <div class="imageDiv">
                            <h1 class="text-center">Main Menu</h1>
                            <ul class="menuList">
                                <li  *ngFor="let list of  menulist;"  [ngClass]="{'active':list.isactive == true}">  <span class="icon"> <i class="fa fa-chevron-right" aria-hidden="true"></i></span><span class="px-2 d-block showmenulist" >{{list.menu_Name}}</span> </li>
                               
                            </ul>
                     </div>
               </div>
        </div>
        <div class="buttonselecte">
             <img src="./assets/imgs/start_Button.png" alt="start_Button" (click)="firstScreenBoolean = !firstScreenBoolean;clickOnStartButton()">
             <img src="./assets/imgs/select_button.png" alt="select_button" style="margin-left: 14px;">
        </div>
        <div class="buttonAandB">
           <span  style="margin-left: 40px;"> <img src="./assets/imgs/button_a.png" alt="button_a"  (click)="ClickOnButtonA(content)"></span> 
           <span>
             <img src="./assets/imgs/button_b.png" alt="button_b" >
           </span> 
        </div>

        <div class="joyhandle">
            <div class="arrowbuttondiv ">
                <button class="square1 uptriangle" (click)="bottomupButton()"><i class="fa fa-caret-up" aria-hidden="true"></i></button>
                <button class="square1 downtriangle" (click)="downButtonClick()"><i class="fa fa-caret-down" aria-hidden="true" ></i></button>
                <button class="square1 lefttriangle"><i class="fa fa-caret-left" aria-hidden="true"></i> </button>
                <button class="square1 righttriangle"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
            </div>
        </div>

        </div>
     
 </div>

 <div class="mobileview">
    <div class="imgdestopimageDiv">
        <img src="./assets/imgs/mobileview_Boarder.png" alt="desktopBorder">
       
        <div class="display">
            <div class="startingimage" *ngIf="firstScreenBoolean == true">
                  <div class="imageDiv">
                      <img src="./assets/imgs/rumor2.png" alt="rumor2">
                      <div class="pressimagetext">
                           <p><span><i class="fa fa-caret-right" aria-hidden="true"></i></span> Press Start</p>
                      </div>
                  </div>
            </div>
            <div class="commingsoonImage" *ngIf="commingsoonBoolean == true">
                  <div class="imageDiv">
                      <img src="./assets/imgs/comming.png" alt="comming">
                      <div class="pressimagetext">
                           <p>Comming Soon</p>
                      </div>
                  </div>
            </div>
            <div class="screenSecond" *ngIf="firstScreenBoolean == false && commingsoonBoolean == false">
                  <div class="imageDiv">
                         <h1 class="text-center">Main Menu</h1>
                         <ul class="menuList">
                             <li  *ngFor="let list of  menulist;"  [ngClass]="{'active':list.isactive == true}">  <span class="icon"> <i class="fa fa-chevron-right" aria-hidden="true"></i></span><span class="px-2 d-block showmenulist" >{{list.menu_Name}}</span> </li>
                            
                         </ul>
                  </div>
            </div>
     </div>
     <div class="buttonselecte">
         <span>
            <img src="./assets/imgs/Mobile_start_button.png" alt="start_Button" (click)="firstScreenBoolean = !firstScreenBoolean;clickOnStartButton()">
            <p>Start</p>
         </span>
         <span>
        <img src="./assets/imgs/Mobile_start_button.png" alt="select_button" >
           
            <p>Select</p>
         </span>
   </div>
   <div class="buttonAandB">
      <span  style="margin-left: 62px;"> <img src="./assets/imgs/mobile_a _Button.png" alt="button_a"  (click)="ClickOnButtonA(content)"></span> 
      <span>
        <img src="./assets/imgs/mobile _B_Button.png" alt="button_b" >
      </span> 
   </div>

   <div class="joyhandle">
       <div class="arrowbuttondiv ">
           <button class="square1 uptriangle" (click)="bottomupButton()"><i class="fa fa-caret-up" aria-hidden="true"></i></button>
           <button class="square1 downtriangle" (click)="downButtonClick()"><i class="fa fa-caret-down" aria-hidden="true" ></i></button>
           <button class="square1 lefttriangle"><i class="fa fa-caret-left" aria-hidden="true"></i> </button>
           <button class="square1 righttriangle"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
       </div>
   </div>
    
    </div>
 </div>



    <!-- <div class="gameFrame">
        <div class="d-flex flex-column powerButton">
        <i class="fa fa-circle power" aria-hidden="true"></i>
        <span>power</span>
        </div>
        <div class="insideartrist">
            <p class="heading mb-0">Artist</p>
          <hr>
            <ul class="menulist  px-3 mb-0">
                <li  *ngFor="let artist of  menulist;"  [ngClass]="{'active':artist.isactive == true}">  <span class="icon"> <i class="fa fa-chevron-right" aria-hidden="true"></i></span>z <span class="px-2 d-block showmenulist" >{{artist.artist_Name}}</span> </li>
            </ul>
        </div>
        <img class="logoImage" src="./assets/imgs/rumor_white_logo.png" alt="logo">

       
    </div> -->
    <!-- <div class="d-flex  justify-content-between gamebutton" >
        <div class="arrowbuttondiv ">
            <button class="square1 uptriangle" (click)="bottomupButton()"><i class="fa fa-caret-up" aria-hidden="true"></i></button>
            <button class="square1 downtriangle" (click)="downButtonClick()"><i class="fa fa-caret-down" aria-hidden="true" ></i></button>
            <button class="square1 lefttriangle"><i class="fa fa-caret-left" aria-hidden="true"></i> </button>
            <button class="square1 righttriangle"><i class="fa fa-caret-right" aria-hidden="true"></i></button>
        </div>
        <div class="actionbutton ">
            <div class="d-block buttonA">
                <button class=""> <span>A</span></button>
                
            </div>
            <div class="d-block buttonB">
                <button> <span>B</span></button>
            </div>
        </div>
    </div> -->
    <!-- <div class="d-block text-center bottomSelectectButton ">
            <button><span>Start</span></button>
            <button><span>Select</span></button>
    </div> -->
</div>


<ng-template #content class="gamemodal">
    <div class="modal-body mobileviewModal" style="text-align: center; ">
            <ul class="gamelistMObileView">
                <li (click)="openNewLink()">
                    <img src="./assets/imgs/cartridge-grey-blank.png" alt="cartridge-grey-blank">
                     <div class="backImgae">
                         <img src="./assets/imgs/tobutobugirl.png" alt="">
                     </div>
                </li>
                <li (click)="openNewLink()">
                    <img src="./assets/imgs/cartridge-grey-blank.png" alt="cartridge-grey-blank">
                    <div class="backImgae">
                        <img src="./assets/imgs/ucity.png" alt="">
                    </div>
                </li>
                <li (click)="openNewLink()">
                    <img src="./assets/imgs/cartridge-grey-blank.png" alt="cartridge-grey-blank">
                
                    <div class="backImgae">
                        <img src="./assets/imgs/geometrix.png" alt="">
                    </div>
                </li>
            </ul>
          
    </div>
  </ng-template>