import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './conponentPacakage/home/home.component';
import { NavbarComponent } from './conponentPacakage/navbar/navbar.component';
import { FullComponent } from './layout/full/full.component';
import { BlankComponent } from './layout/blank/blank.component';
import { AboutComponent } from './conponentPacakage/about/about.component';
import { SocialComponent } from './conponentPacakage/social/social.component';
import { MessageComponent } from './conponentPacakage/message/message.component';
import { ProjectComponent } from './conponentPacakage/project/project.component';
import { MaterialModule } from './material/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ArtistComponent } from './conponentPacakage/artist/artist.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FullComponent,
    BlankComponent,
    AboutComponent,
    SocialComponent,
    MessageComponent,
    ProjectComponent,
    ArtistComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
      DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
