import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';``
import * as jQuery from 'jquery';import { Location } from '@angular/common';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
   
  constructor() { }

  ngOnInit(): void {

    jQuery.fn.carousel = function(opts) {
        opts = jQuery.extend({}, jQuery.fn.carousel.defs, opts);
        this.initialize = function() {
            return this;
        }
        jQuery.fn.carousel.defs = {};
    
        var instance = this;
        var element = jQuery(this);
        var totalPages = $("#about-carousel").children().length;
        var currentPage = 1;
    
        this.changePage = function(step) {
            $("#about-page-down").css({ color: "white", cursor: "pointer" });
    
            $("#about-page-up").css({ color: "white", cursor: "pointer" });
    
            currentPage += step;
    
            if (currentPage > 1) {
                currentPage = 1;
            }
            if (currentPage < 1 - totalPages + 1) {
                currentPage = 1 - totalPages + 1;
            }
    
            if (currentPage == 1) {
                $("#about-page-up").css({ color: "gray", cursor: "pointer" });
            }
    
            if (currentPage == 1 - totalPages + 1) {
                $("#about-page-down").css({ color: "gray", cursor: "pointer" });
            }
           
                if( currentPage == 0){
                    $("#about-carousel").css("transform", "translateY(-100%)");
                }else
                {
                    $("#about-carousel").css("transform", "translateY(0%)");   
                }
            
        }
    
        return this.initialize();
    }

  this.initAbout();

  }


   initAbout() {
  let _carousel = $("#about-carousel").carousel({});

    $("#about-page-up").click(function() {
        _carousel.changePage(1);
    });
    $("#about-page-down").click(function() {
       _carousel.changePage(-1);
    });
}







}
