import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CommonMethods } from 'src/app/UtillPackage/utilPackage/common-method';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {
  Tag="ArtistComponent";
  firstScreenBoolean=true
  secondScreenBoolean=true
  commingsoonBoolean=false
  menulist:Array<any>=[];
  countNumber:number= 0
  screenHeight: number;
  screenWidthsecond: number;
  nbgModalRef:NgbModalRef
  constructor(public ngbModal:NgbModal) {
    this.countNumber=0
    this.menulist=[]
    this.menulist.push(
      {
        "menu_id":"1001",
        "menu_Name":"Artist",
        "isactive":true
      },
      {
        "menu_id":"1002",
        "menu_Name":"Shop",
        "isactive":false
      },
      {
        "menu_id":"1003",
        "menu_Name":"Member",
        "isactive":false
      },
      {
        "menu_id":"1003",
        "menu_Name":"Games",
        "isactive":false
      }
    )

    this.screenHeight=0;
    this.screenWidthsecond=0;

   }


   downButtonClick(){

  
    if(this.firstScreenBoolean == false && this.commingsoonBoolean == false){
      if(this.countNumber != (this.menulist.length - 1)){
        this.menulist[this.countNumber].isactive = false
        this.countNumber++;
        this.menulist[this.countNumber].isactive = true
      }
    }
   

     
   }
   bottomupButton(){
     if(this.firstScreenBoolean == false && this.commingsoonBoolean == false){
      if(this.countNumber != 0)
      {
       this.menulist[this.countNumber].isactive = false
       this.countNumber--;
       this.menulist[this.countNumber].isactive = true
      }
     }
    
   }

  ngOnInit(): void {
  }





  ClickOnButtonA(content){
      if(this.commingsoonBoolean == false && this.firstScreenBoolean == false){

        if(this.menulist[3].isactive ==true){
         
            this.openModal(content)
          

        }else{
          this.commingsoonBoolean=true;
          this.firstScreenBoolean=false;
        }
      }
  }

  clickOnStartButton(){
      if(  this.commingsoonBoolean == true)
      {
        this.commingsoonBoolean=false
      }
  }


  openModal(content) {
    this.nbgModalRef = this.ngbModal.open(content, { centered: true });
    //  this. modalReference.componentInstance.actionMessage = this.actionmessage;
  }
  JoinAndClose() {
    this.nbgModalRef.close();
  }

    openNewLink()
    {
      this.JoinAndClose();
      window.location.href='https://rumorrecords.com/game';
    }

}
