import { Component, OnInit } from '@angular/core';
import { CommonMethods } from 'src/app/UtillPackage/utilPackage/common-method';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  name: string;
  message: string;
  showMessageDoneBoolean=false
  constructor(public httpClient:HttpClient) {
    this.name = "";
    this.message = "";
    this.showMessageDoneBoolean=false
  }

  ngOnInit(): void {
  }


  validation() {
     
    let  name =  (document.getElementById('name') as HTMLInputElement) 
    let message = (document.getElementById('message') as HTMLInputElement)

    if (this.name.trim().length == 0 && this.message.trim().length == 0) {

      name.style.animation="shake 0.5s both"
      message.style.animation="shake 0.5s both" 
      
      setTimeout(() => {
      name.style.animation="none"
      message.style.animation="none" 
      }, 500);

      return false
    } else if (this.name.trim().length == 0) {
      name.style.animation="shake 0.5s both"
      setTimeout(() => {
        name.style.animation="none"
       
      }, 500);
      return false
    } else if (this.message.trim().length == 0) {
      message.style.animation = "shake 0.5s both";
      setTimeout(() => {
        message.style.animation="none" 
      }, 500);
      return false
    }else{
      return true
    }
  }


  sendMessage() {
      if(this.validation()){
        const params = new HttpParams()
        .set("name",this.name)
        .set("message",this.message) 
        this.httpClient.post("https://rumorrecords.com/sendEmail/sendEmail.php", params).subscribe((responseData) => {
          if(responseData['status'] == true){
            // CommonMethods.showconsole("Working","")

             let  mybutton =(document.getElementById('send') as HTMLButtonElement);
             let  redo =(document.getElementById('redo') as HTMLButtonElement);
             let  contact =(document.getElementById('myform') as HTMLElement);
             let  confirm =(document.getElementById('confirmMessage') as HTMLElement);
             redo.style.animation="none" ;
             mybutton.style.animation="sendButton 1s ease-in-out forwards" ;
              setTimeout(() => {
                contact.style.opacity="0";
                  setTimeout(() => {
                    // this.showMessageDoneBoolean=true;
                    contact.style.display="none";
                    confirm.style.animation="fadeIn ease 3s";
                    confirm.style.display="initial";
                    confirm.style.opacity="1";
                    this.name="";
                    this.message="";
                    }, 1000);
              }, 1200);
          }else{
          }
        })
        
      
      }
  }


  redoMessage(){
    let  mybutton =(document.getElementById('send') as HTMLButtonElement);
    let  redo =(document.getElementById('redo') as HTMLButtonElement);
    let  contact =(document.getElementById('myform') as HTMLElement);
    let  confirm =(document.getElementById('confirmMessage') as HTMLElement);
    redo.style.animation="sendButton 1s ease-in-out forwards" ;
    mybutton.style.animation="none" ;
     setTimeout(() => {
      confirm.style.opacity="0";
         setTimeout(() => {
           // this.showMessageDoneBoolean=true;
           confirm.style.display="none";
           contact.style.animation="fadeIn ease 3s";
           contact.style.display="initial";
           contact.style.opacity="1";
          
           }, 1000);
     }, 1200);


  }



  sendMessageApi(){

    
  }

}
