import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

   gonetBoolen =false;
  constructor(public router:Router) { this.gonetBoolen=false }

  ngOnInit(): void {
    this.gotoNext();
  }

  gotoNext(){
    this.gonetBoolen=true
    setTimeout(() => {
      this.router.navigate(['/nav'])  
    }, 1500);
    
  }

}
