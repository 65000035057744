<div class="container">
    <div class="d-flex flex-column justify-content-center socialIcons">
        <a><i class="fa fa-soundcloud" aria-hidden="true"></i></a>
        <a><i class="fa fa-facebook" aria-hidden="true"></i></a>
        <a href="https://www.instagram.com/rumor/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
    </div>
    <!-- <ul class="p-0 listgroup"> -->
        <!-- <li class="list-item">
            <a href="https://github.com/alex-shortt " target="_blank"><i class="fa fa-github" aria-hidden="true"  ></i></a>
        </li> -->
        <!-- <li class="list-item"> -->
            
        <!-- </li> -->
        <!-- <li class="list-item"> -->
            <!-- <a><i class="fa fa-facebook" aria-hidden="true"></i></a> -->
        <!-- </li> -->
        <!-- <li class="list-item"> -->
            <!-- <a href="https://www.instagram.com/rumor/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a> -->
        <!-- </li> -->
        <!-- <li class="list-item">
            <a href="https://www.linkedin.com/in/alexshortt/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
        </li> -->
        <!-- <li class="list-item">
            <a (click)="back()"><i class="fa fa-arrow-left" aria-hidden="true"></i></a>
        </li>
    </ul> -->
</div>