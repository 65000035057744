<div class="container">
    <div class="card custom-card" id="parallax-wrapper" [ngClass]="{'socialPageActiveCrad ': addclassSicialActive == true,'artistclass': addclassArtistActive}">
        <h1 class="privousButton" *ngIf="showpreviousButton" (click)="back()">Previous</h1>
        <div class="tophandImage helpingHand parallax" data-revert="true" data-speed="1.6">
            <img src="./assets/imgs/hand-help.png" alt="top Heand">
        </div>
    <div class="mainContent" >
            <router-outlet></router-outlet>
        </div>
        <div class="bottomhandImage helpingHand parallax" data-speed="3.2">
            <img src="./assets/imgs/hand-despair.png" alt="bottom hand">
        </div>
    </div>
</div>