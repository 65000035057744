<div class="container-fluid p-0">
    <form class="custom-form" id="myform">
        <div class="form-group custom-group">
            <label for="name">Name/Email:</label>
            <input type="text" id="name" name="name-email" class="form-control custom-input" [(ngModel)]="name" autofocus required>
        </div>
        <div class="form-group custom-group">
            <label for="message">Message</label>
            <textarea name="message" id="message" cols="30" rows="7" class="form-control custom-input textarea" [(ngModel)]="message" required></textarea>
        </div>
        <div class="action-button">
            <button class="btn btn-block customsendButton menu-message-contact-send" id="send" (click)="sendMessage()">Send</button>
        </div>
    </form>
    <div class="menu-message-confirm menu-message-tab" id="confirmMessage">
        <h1 class="menu-message-confirm-text">Message sent successfully.</h1>
        <h1 class="menu-message-confirm-text">Thanks!</h1>
        <h2 id="menu-message-confirm-redo" class="menu-message-confirm-redo" id="redo" (click)="redoMessage()">Send Another?</h2>
    </div>
</div>