import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'; ``
import * as jQuery from 'jquery';
import { CommonMethods } from 'src/app/UtillPackage/utilPackage/common-method';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.css']
})
export class FullComponent implements OnInit {
  _book;
  Tag="FullComponent"
  isactiveartist=false;
 
  constructor(public router :Router) {

    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {

         CommonMethods.showconsole(this.Tag,"Current Url:- "+this.router.url)
          if(this.router.url == '/artist' ){
        
            this.isactiveartist=true;
             CommonMethods.showconsole(this.Tag,"Working Is ")
          }else{
            this.isactiveartist=false;
          
          }

      }

    })


   }

  ngOnInit(): void {
  
  
// this.initProjects()
  }



  

}
