<div class="conatiner-fluid p-4 projectConatiner myconatiner">



    <div class='book'>
        <ul class='book-cover-front'>
            <li>
                <div>
                    <img src="./assets/imgs/rumor_white_logo.png" class="mainimgClass" alt="main_logo">
                </div>
            </li>
            <li></li>
        </ul>
        <ul class='book-page'>
            <li class="page page-toc" style="transform: rotateY(-145deg) translateZ(0px); z-index: 0;">
                <h3>Table of Contents</h3>
                <hr />
                <h4 data-page="0" (click)="gotoNavigation('artist')">Enter</h4>
                <h4 data-page="2" >About</h4>
                <h4 data-page="3" >Message</h4>
                <h4 data-page="4" >Social</h4>
             
            </li>
            <li class="page page-awge">
                <div class="page-content">
                   
                    <app-about></app-about>
                </div>
                <h4 data-page="1" class="page-back"> - back - </h4>
            </li>
            <li class="page page-awge-forums">
                <div class="page-content messagesection">
                    <app-message></app-message>
   
                   
                </div>
                <h4 data-page="1" class="page-back"> - back - </h4>
            </li>
            <li class="page page-revenge-x-storm">
                <div class="page-content">
                    
                    <app-social></app-social>
                </div>
                <h4 data-page="1" class="page-back"> - back - </h4>
            </li>
            <!-- <li class="page page-nessly">
                <div class="page-content">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="./assets/imgs/projects/nessly/nessly-home.png" />
                                <img class="page-content-image" src="./assets/imgs/projects/nessly/nessly-model.png" />
                                <img class="page-content-image" src="./assets/imgs/projects/nessly/nessly-store.png" />
                            </div>
                        </div>
                        <h2 class="page-content-text">Nessly</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://nesslyworld.com">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Nessly description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back"> - back - </h4>
            </li> -->
           
        </ul>
        <ul class='book-cover-back'>
            <li>
                <div class="backcover-content">
                    <div class="deepspace-space-stars deepspace-space"></div>
                    <div class="deepspace-space-twinkling deepspace-space"></div>
                    <div class="deepspace-comet deepspace-comet-1"></div>
                    <div class="deepspace-comet deepspace-comet-2"></div>
                    <div class="deepspace-comet deepspace-comet-3"></div>
                    <div class="deepspace-comet deepspace-comet-4"></div>
                    <img id="backcover-saturn" (click)="_book.changePage(1)" class="backcover-saturn backcover-saturn-hidden" src="./assets/imgs/space/saturn.png" />
                </div>
                <img id="backcover-girl" class="parallax backcover-girl hidden" data-speed="0.5" src="./assets/imgs/space/girl.png" />
            </li>
            <li></li>
        </ul>
        <ul class='book-spine'>
            <li></li>
            <li></li>
        </ul>
    </div>

    <!-- <div class="book">
        <ul class="book-cover-front" style="transform: rotateY(-145deg) translateZ(0px); z-index: 0;">
            <li>
                <div>
                    <p>PROJECTS</p>
                </div>
            </li>
            <li></li>
        </ul>
        <ul class="book-page">
            <li class="page page-toc" style="transform: rotateY(-32.625deg); transition-duration: 2.00168s;">
                <h3>Table of Contents</h3>
                <hr>
                <h4 data-page="2">AWGE</h4>
                <h4 data-page="3">AWGE Forums</h4>
                <h4 data-page="4">Revenge X Storm</h4>
                <h4 data-page="5">Nessly</h4>
                <h4 data-page="6">Pia Mia</h4>
                <h4 data-page="7">SoundDown</h4>
                <h4 data-page="8">Portal Gun Mod</h4>
                <h4 data-page="9">?????</h4>
             
                <h4 data-page="0" class="page-back"> - back - </h4>
            </li>
            <li class="page page-awge" style="transform: rotateY(-30.25deg); transition-duration: 1.92205s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="assets/projects/awge/awge-landing.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-home.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-shop.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-videos.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-contact.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">AWGE</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://www.awgeshit.com">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            I built this site in August 2016, and since then it has reached over a million users and securely captured thousands of purchases.
                            <br>
                            <br> The site displays a consistent retro aesthetic down to the smallest detail, offering an immersive experience for the user.
                            <br>
                            <br> It offers media curated by AWGE members, a secure shop, 3d interaction with the products, and a contact form.
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-awge-forums" style="transform: rotateY(-27.875deg); transition-duration: 1.95967s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="assets/projects/awge/awge-landing.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-home.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-shop.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-videos.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-contact.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">AWGE Forums</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://forums.awgeshit.com">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Awge Forums description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-revenge-x-storm" style="transform: rotateY(-25.5deg); transition-duration: 1.87624s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="assets/projects/revenge/revenge-landing.png">
                                <img class="page-content-image" src="assets/projects/revenge/revenge-shop.png">
                                <img class="page-content-image" src="assets/projects/revenge/revenge-shoe.png">
                                <img class="page-content-image" src="assets/projects/revenge/revenge-kylie.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">Revenge X Storm</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://www.revengexstorm.com">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Revenge x Storm description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-nessly" style="transform: rotateY(-23.125deg); transition-duration: 1.90005s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="assets/projects/nessly/nessly-home.png">
                                <img class="page-content-image" src="assets/projects/nessly/nessly-model.png">
                                <img class="page-content-image" src="assets/projects/nessly/nessly-store.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">Nessly</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://nesslyworld.com">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Nessly description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-piamia" style="transform: rotateY(-20.75deg); transition-duration: 1.84413s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="assets/projects/awge/awge-landing.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-home.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-shop.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-videos.png">
                                <img class="page-content-image" src="assets/projects/awge/awge-contact.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">Pia Mia</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="http://alexshortt.com/projects/piamia">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Pia Mia description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-sounddown" style="transform: rotateY(-18.375deg); transition-duration: 1.82192s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="/assets/projects/sounddown/sounddown-listing.png">
                                <img class="page-content-image" src="/assets/projects/sounddown/sounddown-popup.png">
                                <img class="page-content-image" src="/assets/projects/sounddown/sounddown-modal.png">
                                <img class="page-content-image" src="/assets/projects/sounddown/sounddown-download.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">SoundDown</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://chrome.google.com/webstore/detail/sounddown/ljjaomnfoepedhkncdffdadnpmckoohb">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            SoundDown description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
            <li class="page page-portalgun" style="transform: rotateY(-16deg); transition-duration: 1.76462s;">
                <div class="page-content hidden">
                    <div class="page-content-cover">
                        <div class="page-content-cover-dir">
                            <div class="page-content-cover-left action-prev"></div>
                            <div class="page-content-cover-right action-next"></div>
                        </div>
                        <div class="page-content-gallerymask">
                            <div id="gallery" class="page-content-gallery">
                                <img class="page-content-image" src="/assets/projects/portal/portal-listing.png">
                                <img class="page-content-image" src="/assets/projects/portal/portal-game.png">
                                <img class="page-content-image" src="/assets/projects/portal/portal-poster.png">
                                <img class="page-content-image" src="/assets/projects/portal/portal-youtube.png">
                                <img class="page-content-image" src="/assets/projects/portal/portal-kwebbelkop.png">
                            </div>
                        </div>
                        <h2 class="page-content-text">Portal Gun Mod</h2>
                    </div>
                    <div class="page-content-actions">
                        <h3 class="page-content-action action-gallery">gallery</h3>
                        <h3 class="page-content-action action-visit" data-link="https://www.gta5-mods.com/scripts/portal-gun-net">visit</h3>
                    </div>
                    <div class="page-content-desc">
                        <h4>
                            Portal Gun description...
                        </h4>
                    </div>
                </div>
                <h4 data-page="1" class="page-back hidden"> - back - </h4>
            </li>
        </ul>
        <ul class="book-cover-back">
            <li>
                <div class="backcover-content">
                    <div class="deepspace-space-stars deepspace-space"></div>
                    <div class="deepspace-space-twinkling deepspace-space"></div>
                    <div class="deepspace-comet deepspace-comet-1"></div>
                    <div class="deepspace-comet deepspace-comet-2"></div>
                    <div class="deepspace-comet deepspace-comet-3"></div>
                    <div class="deepspace-comet deepspace-comet-4"></div>
                    <img id="backcover-saturn" onclick="_book.changePage(1)" class="backcover-saturn backcover-saturn-hidden" src="/assets/space/saturn.png">
                </div>
                <img id="backcover-girl" class="parallax backcover-girl hidden" data-speed="0.5" src="/assets/space/girl.png" style="transform: matrix(1, 0, 0, 1, 0.0825, 1.18875);">
              
            </li>
            <li></li>
        </ul>
        <ul class="book-spine">
            <li></li>
            <li></li>
        </ul>
    </div> -->


</div>